import React from 'react';
import Select from "../../elements/Select";
import TextInput from "../../elements/TextInput";
import RelatedItemsList from "../../elements/RelatedItemsList";

const BusinessPartnerAddressCard = ({ state, contractsList, cardHeader }) => {

  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const setCurrentFormState = state.setCurrentFormState;
  const currentFormState = state.currentFormState;


  return (
    <>
      <div className="card-header">
        { cardHeader }
      </div>
      <div className="card-body">
        <div className="row">
        <label htmlFor="kontrakty" className="">Kontrakty</label>

          <RelatedItemsList
            label="Kontrakty"
            propertyName="positionId"
            required={false}
            state={state}
            itemsList={contractsList}
            itemName="contracts"
            displayNames={["name"]}
          />

        </div>
      </div>
    </>
  );
};

export default BusinessPartnerAddressCard;
