import React from 'react';
import { Button } from 'react-bootstrap';

const CompanyPreview = ({ state, formHeader, getCompleteItem, itemDictionary, itemConfig }) => {
  const { itemsList, setCurrentItem, setBackupItem, setCurrentFormState, currentFormState } = state;



  const handleEditClick = async () => {
    if (getCompleteItem) {
      const completeItem = await getCompleteItem(itemsList[0].id);
      setCurrentItem(completeItem);
      setBackupItem(completeItem);
    } else {
      setCurrentItem(itemsList[0]);
      setBackupItem(itemsList[0]);
    }
    setCurrentFormState({
      ...currentFormState,
      showForm: true,
      formDescription: formHeader
    });
  };

  const getValueByPath = (object, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], object);
  };


  const getDisplayName = (field) => {
    const baseKey = field.includes('.') ? field.split('.')[0] : field;
    return itemDictionary[baseKey] || field;
  };


  const formatValue = (value, key) => {
    if (typeof value === 'boolean') {
      return value ? 'TAK' : 'NIE';
    } else if (typeof value === 'object' && value !== null) {
      return value[key];
    }
    return value;
  };


  const renderSection = (sectionKey) => {
    const section = itemConfig[sectionKey];
    return (
      <div key={sectionKey} className="mb-4 ms-5">
        <h6>{section.title}</h6>
        {section.fields.map(field => (
          <div key={field} className="row mb-2">
            <div className="col-md-3">{getDisplayName(field)}</div>
            <div className="col-md-9">{formatValue(getValueByPath(itemsList[0], field), field.split('.').pop())}</div>
          </div>
        ))}
      </div>
    );
  };


  return (
    <div className="row mt-4">

      {itemConfig && Object.keys(itemConfig).map(sectionKey => renderSection(sectionKey))}

      <div className="modal-footer">
        <Button onClick={handleEditClick}>Edytuj</Button>
      </div>
    </div>
  );
};

export default CompanyPreview;
