import Register from './components/homepage/Register';
import Login from './components/homepage/Login';
import App from './components/app/App';
import Layout from './components/layout/Layout';
import Missing from './components/app/missing/Missing';
import Unauthorized from './components/app/unathorized/Unauthorized';
import RequireAuth from './auth/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import PersistLogin from "./auth/PersistLogin";
import Homepage from "./components/homepage/Homepage";
import Dashboard from "./components/app/dashboard/Dashboard";

import './css/App.css';
import './css/Form.css';
import './css/datatables.css';
import './css/Tables.css';

import User from "./components/app/admin/users/Users";
import Roles from "./components/app/admin/roles/Roles";
import AbsenceType from "./components/app/admin/absence_types/AbsenceType";
import BusinessTripType from "./components/app/admin/business_trip_types/BusinessTripType";
import CertificateType from "./components/app/admin/certificate_types/CertificateType";
import IdentityCardType from "./components/app/admin/identity_card_types/IdentityCardType";
import Position from "./components/app/admin/positions/Position";
import DocumentType from "./components/app/admin/document-types/DocumentType";
import TaxOffice from "./components/app/admin/tax_offices/TaxOffice";
import TrainingType from "./components/app/admin/training_types/TrainingType";
import Department from "./components/app/admin/departments/Department";
import MedicalExaminationType from "./components/app/admin/medical_examination_types/MedicalExaminationType";
import ContractType from "./components/app/admin/contract_types/ContractType";
import NfzBranch from "./components/app/admin/nfz_branches/NfzBranch";
import LegalEntityType from "./components/app/admin/legal_entity_types/LegalEntityType";
import BusinessPartner from "./components/app/business_partner/BusinessPartner";
import Country from "./components/app/admin/country/Country";
import Voivodeship from "./components/app/admin/voivodeship/Voivodeship";
import WorkingTime from "./components/app/admin/working-time/WorkingTime";
import NonWorkingDays from "./components/app/admin/non_working_days/NonWorkingDays";
import Infraction from "./components/app/admin/infractions/Infraction";
import Commendation from "./components/app/admin/commendations/Commendation";
import Employee from "./components/app/admin/employees/Employee";
import Gender from "./components/app/admin/gender/Gender";
import Settings from "./components/app/admin/settings/Settings";
import HiringStage from "./components/app/admin/hiring-stages/HiringStage";
import Contract from "./components/app/contracts/Contract";
import DocumentNumberingPattern from "./components/app/admin/document_numbering_patterns/DocumentNumberingPattern";
import Company from "./components/app/admin/company/Company";


const ROLES = {
  'User': 'ROLE_USER',
  'Manager': 'ROLE_MANAGER',
  'Admin': 'ROLE_ADMIN'
}

function Site() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
      {/* public routes */}
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      {/*<Route path="homepage" element={<Homepage />} />*/}
      <Route path="unauthorized" element={<Unauthorized />} />

      {/* protected routes*/}
      <Route element={<PersistLogin />}>
        <Route path="home" element={<Homepage />} />
        <Route path="" element={<Homepage />} />
        <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}>
          <Route path="/app" element={<App />} >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="department" element={<Department />} />
            <Route path="medical-examination-type" element={<MedicalExaminationType />} />
            <Route path="contract-type" element={<ContractType />} />
            <Route path="contract" element={<Contract />} />
            <Route path="company" element={<Company />} />
            <Route path="document-numbering-pattern" element={<DocumentNumberingPattern />} />
            <Route path="absence-type" element={<AbsenceType />} />
            <Route path="business-trip-type" element={<BusinessTripType />} />
            <Route path="document-type" element={<DocumentType />} />
            <Route path="certificate-type" element={<CertificateType />} />
            <Route path="training-type" element={<TrainingType />} />
            <Route path="identity-card-type" element={<IdentityCardType />} />
            <Route path="position" element={<Position />} />
            <Route path="tax-office" element={<TaxOffice />} />
            <Route path="nfz-branch" element={<NfzBranch />} />
            <Route path="legal-entity-type" element={<LegalEntityType />} />
            <Route path="business-partner" element={<BusinessPartner />} />
            <Route path="non-working-days" element={<NonWorkingDays />} />
            <Route path="country" element={<Country />} />
            <Route path="voivodeship" element={<Voivodeship />} />
            <Route path="gender" element={<Gender />} />
            <Route path="working-time" element={<WorkingTime />} />
            <Route path="infraction" element={<Infraction />} />
            <Route path="commendation" element={<Commendation />} />
            <Route path="users" element={<User />} />
            <Route path="employees" element={<Employee />} />
            <Route path="roles" element={<Roles />} />
            <Route path="settings" element={<Settings />} />
            <Route path="hiring-stage" element={<HiringStage />} />
          </Route>
        </Route>

        {/*<Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}>*/}
        {/*  <Route path="app/dashboard" element={<Dashboard />} />*/}
        {/*</Route>*/}
      </Route>
        {/* catch all */}
      <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default Site;