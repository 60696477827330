import React from 'react';
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Formcheck from "react-bootstrap/FormCheck";


const TableHead = ({ selectedItems, setSelectedItems, filteredRows,
                         columns,
                         setActivePage,
                         filters, setFilters,
                         sort, setSort,
                         itemsIds
                       }) => {


  const handleSort = (accessor) => {
    const column = columns.find(col => col.accessor === accessor);
    if (!column.sortable) {
      return;
    }
    setActivePage(1)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
  }


  const handleSearch = (value, accessor) => {
    setActivePage(1)

    if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [accessor]: value,
      }))
    } else {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters }
        delete updatedFilters[accessor]
        return updatedFilters
      })
    }
  }


  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const newSelectedItems = new Set(filteredRows.map(item => item.id));
      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems(new Set());
    }
  }



  return (
    <thead style={{backgroundColor: "#D9DFF0"}}>
    <tr className = "table-settings-header">
      <th></th>
      {columns.map((column) => {
        if (column.visible !== false) {
          const sortIcon = () => {
            if (column.accessor === sort.orderBy) {
              if (sort.order === 'asc') {
                return <FontAwesomeIcon icon={faSortUp}/>
              }
              return <FontAwesomeIcon icon={faSortDown}/>
            } else {
              return <FontAwesomeIcon icon={faSort} className={"invisible"}/>
            }
          }

          return (
            // <th key={column.accessor} onClick={() => handleSort(column.accessor)}>
            <th key={column.accessor} onClick={column.sortable ? () => handleSort(column.accessor) : undefined} className={column.sortable ? "sortable-header" : "non-sortable-header"}>
              <span>{column.label}</span>
              {column.sortable &&
                <button className="sort-button" onClick={() => {
                  handleSort(column.accessor)
                }}>{sortIcon()}</button>}
            </th>
          )
        }
      })}
    </tr>
    <tr className = {"table-settings-filters"}>
      <th key="select-all-check"><Formcheck
        type="checkbox"
        checked={selectedItems.size === filteredRows.length && filteredRows.length > 0}
        onChange={(e) => handleSelectAll(e.target.checked)}
      ></Formcheck></th>
      {columns.map((column) => {
        if (column.visible !== false) {
          return (
            <th key={`${column.accessor}-filter`}>
              {column.searchable &&
                <input className={"search_textbox"}
                  key={`${column.accessor}-search`}
                  type="search"
                  placeholder={`Szukaj`}// ${column.label}`}
                  value={filters[column.accessor]}
                  onChange={(event) => handleSearch(event.target.value, column.accessor)}
                />}
            </th>
          )
        }
      })}
    </tr>
    </thead>
  );
};

export default TableHead;

