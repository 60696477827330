import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "./useAxiosPrivate";
import {onCloseDeleteWarningDialog} from "../helpers/ComponentHelper";

const useCrud = (dataUrl) => {

  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const createItem = async (url, item, state) => {
    const itemsList = state.itemsList;
    const setItems = state.setItems;

    try {
      const response = await axiosPrivate.post(url, item);
      setItems([...itemsList, response.data]);
      return response;
    } catch (error) {

      if (error.response) {
        return error.response;
      } else {
        console.error("Error without response: ", error);
        throw error;
      }
    }
  }


  const updateItem = async (url, item, state) => {
    const itemsList = state.itemsList;
    const setItems = state.setItems;

    try {
      const response = await axiosPrivate.put(url, item);
      const data = await response.data;
      setItems(
        itemsList.map((i) =>
          i.id === item.id ? data : i));
      return response;
      } catch (error) {
        if (error.response) {
          return error.response;
        } else {
          console.error("Error without response: ", error);
          throw error;
        }
      }


  }


  const updateItemOld = async (url, item, state) => {
    //console.log("UPDATE");
    const itemsList = state.itemsList;
    const setItems = state.setItems;
    //console.log("item to update:");
    //console.log(item);
    const response = await axiosPrivate.put(url, item);
    //console.log("CRUD UPDATE: " + response.status);
    const data = await response.data;
    //console.log("UPDATED");
    //console.log(response.data);
    setItems(
      itemsList.map((i) =>
        i.id === item.id ? data : i));

    return response;
  }


  const deleteItem = async (url, currentItemId, state) => {
    const itemsList = state.itemsList;
    const setItems = state.setItems;

    try {
      const response = await axiosPrivate.delete(url);
      if (response.status === 200) {
        setItems(itemsList.filter((i) => i.id !== currentItemId));
        onCloseDeleteWarningDialog({state});
      }
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        console.error("Error without response: ", error);
        throw error;
      }
    }

  }



  const deleteItemOld = async (url, currentItemId, state) => {
    const itemsList = state.itemsList;
    const setItems = state.setItems;
    const response = await axiosPrivate.delete(url);

    if (response.status === 200) {
      setItems(itemsList.filter((i) => i.id !== currentItemId));
      onCloseDeleteWarningDialog({state});
    }
    return response.status;
  }


  const getRelatedChildrenByParentId = async (url, id, setRelatedItems) => {
    // console.log("GET RELATED CHILDREN FROM " + url + " FOR ID " + id);

    const setItems = setRelatedItems;

    try {
      const response = await axiosPrivate.get(url);
      setItems(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }


  const getItems = async (url) => {
    let response;
    try {
      response = await axiosPrivate.get(url);
      setFetchError(null);
      return response;
    } catch (err) {
      setFetchError(err.message);
      return err.message;
    } finally {
      setIsLoading(false);
    }
  }


  const createItemObsolete = async (url, item, state) => {
    const itemsList = state.itemsList;
    const setItems = state.setItems;

    const response = await axiosPrivate.post(url, item);
    setItems([...itemsList, response.data]);
    return response.status;
  }


  return { createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId };

};

export default useCrud;
