import React, { useEffect, useState } from "react";
import { Modal, Button, DatePicker } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import ModalFooter from "../../common/ModalFooter";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultFormState } from "../../../../defaults/Forms";
import { nonWorkingDaysDefault } from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import ItemDetailsModalHeader from "../../common/ItemDetailsModalHeader";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import useCrud from "../../../../hooks/useCrud";
import { Table } from "../../../table/Table";

import {MDBDataTableV5} from "mdbreact";

const NonWorkingDays = () => {

  const dataUrl ="/non-working-day";
  const relatedItemsUrl = null; // if no need to check it, initialize with null and remove RelatedItemsList from details modal
  const defaultItem = nonWorkingDaysDefault;
  const itemName = "dzień wolny od pracy";
  const itemNames = "dni wolne od pracy";
  const navigate = useNavigate();
  const location = useLocation();
  const { createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId } = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);


  // elements related to the item
  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Nazwa", accessor: "name", sortable: true, searchable: true},
    {label: "Data", accessor: "date", sortable: true, searchable: true},
    {label: "Skrócona nazwa", accessor: "shortName", sortable: true, searchable: true},
    {label: "Opis", accessor: "description", sortable: true, searchable: true},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];


  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const onDelete = async () => {
    const response = await deleteItem(`${ dataUrl }/${ currentItem.id }`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', { state: { from: location }, replace: true });
    }
  }

  const onSaveItemClick = async (e) => {
    e.preventDefault();
    if(!currentItem.name || !currentItem.shortName) {
      if(!currentItem.name) {
        let nameInput = document.getElementById("name");
        nameInput.classList.add("form-input-invalid");
        nameInput.placeholder = `ten element jest wymagany`;
      }

      if(!currentItem.shortName) {
        let shortNameInput = document.getElementById("shortName");
        shortNameInput.classList.add("form-input-invalid");
        shortNameInput.placeholder = `ten element jest wymagany`;
      }
      return;
    }
    let response;
    const item = { id: currentItem.id, date: currentItem.date, shortName:currentItem.shortName, name: currentItem.name, description: currentItem.description};
    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${ dataUrl }/${ item.id }`, item, state);
    }
    if (response === 401 || response === 403) navigate('/login', { state: { from: location }, replace: true });
    response && onSaveAndClose({state});
  }

  const checkRelatedItems = async (id) => {
    //const data = await getRelatedChildrenByParentId(`${ relatedItemsUrl }/${ id }`, id, setEventsList);
    //data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
    setAllowDelete(true);
  }

  const onClose = () => {
    onCloseDetails({ state })
  };

  useEffect(() => {
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])

  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])

  useEffect(() => {
    const getData = async () => {
      const response = await getItems(dataUrl);
      if (response.status === 200) {
        setLoading(false);
        setItems(response.data);
      } else if (response.status === 401 || response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true})
      } else {
        alert("Nie udało się pobrać danych.");
      }
    };
    getData();
  }, [])


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: `Tu możesz dodać nowy ${ itemName }.`,
    formHeader: `Nowy ${ itemName }`,
    buttonTitle: `Dodaj`
  }


  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv />
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows = { itemsList }
        columns = { columns }
        state = { state }
        checkRelatedItems = { checkRelatedItems }
        formHeader = {`Edycja ${ itemName }`}
        relatedItemsUrl = { relatedItemsUrl }
      />
  } else {
    dataSectionContent = <div className={"warn_info"}>NIE ZNALEZIONO ŻADNYCH DANYCH</div>
  }

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid px-4">
        <div className="card my-3 shadow mb-5 bg-white rounded">
          <AppComponentCardHeader title={`${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`} buttonProps={addDataButtonProps}/>
          { dataSectionContent }
        </div>

      </div>
      <DeleteWarningModal
        state = { state }
        onDelete = { onDelete }
        deleteItemName ={ itemName } />

      <Modal show={currentFormState.showForm}
             size="xl"
             backdrop="static"
             keyboard={false}
             onHide={ onClose }>
        <ItemDetailsModalHeader title ={ currentFormState.formHeader } />

        <Modal.Body>
          <section className="mb-4">
            <p className="text-center w-responsive mx-auto mb-1 form_test">{ currentFormState.formDescription }</p>
            <div>
              <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon icon={ faExclamationCircle }/>&nbsp;{ currentFormState.formDataChangedWarning }</p>
              <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={ () => {
                restoreFormData({ state })}}>
                Wyczyść zmiany
              </Button>
            </div>

            <div className="row">
              <div className="col-md-12 mb-md-0 mb-5">
                <form id="add-nonworkingdays-form" name="add-nonworkingdays-form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="md-form mb-0">
                            <label htmlFor="name" className="">Nazwa *</label>
                            <TextInput propertyName="name" required={true} state={ state }/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="md-form mb-0">
                            <label htmlFor="shortName" className="">Skrót *</label>
                            <TextInput  propertyName="shortName" maxlen="5" required={true} state={ state }/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="md-form mb-0">
                            <label htmlFor="date" className="">Data *</label>
                            <TextInput propertyName="date" required={true} state={ state }/>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="md-form mb-0">
                            <label htmlFor="description" className="">Opis</label>
                            <TextArea propertyName="description" required={false} rows = "2" state = { state }/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </Modal.Body>
        <ModalFooter
          onDelete = { onDelete }
          onCloseDetails = { onClose }
          onSubmit = { onSaveItemClick }
          state = { state }
        />
      </Modal>

    </div>
  )

}

export default NonWorkingDays;
