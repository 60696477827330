import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faListAlt, faCubes, faUsers} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import '../../css/App.css';
import logo from '../../assets/farma-logo.png';
import {faFileContract} from "@fortawesome/free-solid-svg-icons/faFileContract";
import {faGrip} from "@fortawesome/free-solid-svg-icons/faGrip";
import useAuth from '../../hooks/useAuth';
import {Link} from "react-router-dom";
import MenuLink from "./MenuLink";

const LayoutSidebarLeft = () => {

  const {auth} = useAuth();
  const [isDictionariesVisible, setIsDictionariesVisible] = useState(false);
  const [isAppSettingsVisible, setAppSettingsVisible] = useState(false);
  const [isCompanySettingsVisible, setCompanySettingsVisible] = useState(false);
  const [isOfficeSettingsVisible, setOfficeSettingsVisible] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const toggleDictionariesVisibility = () => {
    setIsDictionariesVisible(!isDictionariesVisible);
  };
  const toggleAppSettingsVisibility = () => {
    setAppSettingsVisible(!isAppSettingsVisible);
  };
  const toggleCompanySettingsVisible = () => {
    setCompanySettingsVisible(!isCompanySettingsVisible);
  };
  const toggleOfficeSettingsVisible = () => {
    setOfficeSettingsVisible(!isOfficeSettingsVisible);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className="sb-sidenav-menu-heading">Core</div>
              <MenuLink path="/app/dashboard" label="Tablica" />
              <MenuLink path="/app/scheduler" label="Grafik" />
            <div className="sb-sidenav-menu-heading">Zasoby</div>
              <MenuLink path="/app/employees" label="Pracownicy" />
              <MenuLink path="/app/equipment" label="Urządzenia" />
            <div className="sb-sidenav-menu-heading">Dokumenty</div>
              <MenuLink path="/app/contract" label="Umowy" />
              <MenuLink path="/app/training" label="Szkolenia" />
            {auth?.roles?.includes("ROLE_ADMIN") ?
              <>
                <div className={`sb-sidenav-menu-heading accordion ${isAppSettingsVisible ? "open" : ""}`} onClick={toggleAppSettingsVisibility}>Ustawienia
                  aplikacji
                </div>
                <div className={`accordion-content ${isAppSettingsVisible ? "expanded" : ""}`}>
                  <MenuLink path="/app/company" label="Firma" />
                  <MenuLink path="/app/users" label="Użytkownicy aplikacji" />
                  <MenuLink path="/app/roles" label="Role użytkowników" />
                  <MenuLink path="/app/settings" label="Ustawienia globalne" />
                </div>

                <div className={`sb-sidenav-menu-heading accordion ${isCompanySettingsVisible ? "open" : ""}`} onClick={toggleCompanySettingsVisible}>Ustawienia
                  firmy
                </div>
                <div className={`accordion-content ${isCompanySettingsVisible ? "expanded" : ""}`}>
                  <MenuLink path="/app/position" label="Stanowiska" />
                  <MenuLink path="/app/department" label="Działy firmy" />
                  <MenuLink path="/app/business-partner" label="Kontrahenci" />
                  <MenuLink path="/app/document-numbering-pattern" label="Wzorce numeracji dokumentów" />
                  <MenuLink path="/app/document-type" label="Typy dokumentów" />
                  <MenuLink path="/app/contracts-templates" label="Wzory umów" />
                  <MenuLink path="/app/user-types" label="Typy użytkowników" />
                </div>

                <div className={`sb-sidenav-menu-heading accordion ${isDictionariesVisible ? "open" : ""}`} onClick={toggleDictionariesVisibility}>słowniki</div>
                <div className={`accordion-content ${isDictionariesVisible ? "expanded" : ""}`}>
                  <MenuLink path="/app/gender" label="Płeć" />
                  <MenuLink path="/app/hiring-stage" label="Etapy zatrudnienia" />
                  <MenuLink path="/app/commendation" label="Typy pochwał" />
                  <MenuLink path="/app/infraction" label="Typy przewinień" />
                  <MenuLink path="/app/absence-type" label="Typy nieobecności" />
                  <MenuLink path="/app/training-type" label="Typy szkoleń" />
                  <MenuLink path="/app/medical-examination-type" label="Typy badań lekarskich" />
                  <MenuLink path="/app/certificate-type" label="Typy certyfikatów" />
                  <MenuLink path="/app/business-trip-type" label="Typy wyjazdów służbowych" />
                  <MenuLink path="/app/identity-card-type" label="Typy dokumentów tożsamości" />
                  <MenuLink path="/app/contract-type" label="Typy umów" />
                  <MenuLink path="/app/wage-type" label="Typy stawek" />
                  <MenuLink path="/app/working-time" label="Wymiary czasu pracy" />
                  <MenuLink path="/app/non-working-days" label="Dni wolne od pracy" />
                </div>

                  <div className={`sb-sidenav-menu-heading accordion ${isOfficeSettingsVisible ? "open" : ""}`} onClick={toggleOfficeSettingsVisible}>ustawienia urzędowe</div>
                  <div className={`accordion-content ${isOfficeSettingsVisible ? "expanded" : ""}`}>
                    <MenuLink path="/app/country" label="Kraje" />
                    <MenuLink path="/app/voivodeship" label="Województwa" />
                    <MenuLink path="/app/tax-office" label="Urzędy skarbowe" />
                    <MenuLink path="/app/nfz-branch" label="Oddziały NFZ" />
                    <MenuLink path="/app/legal-entity-type" label="Typy podmiotów prawnych" />
                </div>
              </> : ""
            }
            <img src={logo} className="App-logo" alt="logo"/>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Zalogowany jako {auth.user} </div>
        </div>
      </nav>
    </div>
  )
};

export default LayoutSidebarLeft;
