import React from 'react';
import { resetInvalidInputField } from "../../helpers/ComponentHelper";
import '../../css/Form.css'

const Toggler = ({ propertyName, state }) => {
  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const itemChanged = state.itemChanged;
  const setItemChanged = state.setItemChanged;

  return (
    <label className="toggler">
      <input
        type="checkbox"
        id = { propertyName }
        name = { propertyName }
        checked={currentItem[propertyName]}
        onChange={() => {
          setItemChanged(!itemChanged);
          setCurrentItem({
            ...currentItem,
            [propertyName]: !currentItem[propertyName]
          });
          setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
          resetInvalidInputField(propertyName);
        }}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Toggler;
