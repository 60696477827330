import React, {useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import ModalFooter from "../common/ModalFooter";
import {useNavigate, useLocation} from "react-router-dom";
import {defaultFormState} from "../../../defaults/Forms";
import {businessPartnerDefault} from "../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails, setForegroundColor
} from "../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../common/AppComponentCardHeader";
import LoadingDataDiv from "../common/LoadingDataDiv";
import DeleteWarningModal from "../common/DeleteWarningModal";
import ItemDetailsModalHeader from "../common/ItemDetailsModalHeader";
import TextInput from "../../elements/TextInput";
import TextArea from "../../elements/TextArea";
import useCrud from "../../../hooks/useCrud";
import {Table} from "../../table/Table";

import Select from "../../elements/Select";
import BusinessPartnerAddressCard from "./BusinessPartnerAddressCard";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import BusinessPartnerContactCard from "./BusinessPartnerContactCard";
import RelatedItemsList from "../../elements/RelatedItemsList";
import BusinessPartnerContractsCard from "./BusinessPartnerContractsCard";

const BusinessPartner = () => {

  const dataUrl = "/business-partners";
  const legalEntityUrl = "/legal-entities";
  const countryUrl = "/countries";
  const relatedItemsUrl = null; // if no need to check it, initialize with null and remove RelatedItemsList from details modal
  const defaultItem = businessPartnerDefault;
  const itemName = "kontrahent";
  const itemNames = "kontrahenci";
  const navigate = useNavigate();
  const location = useLocation();
  const {createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId} = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [legalEntitiesList, setLegalEntities] = useState([]);
  const [countriesList, setCountries] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [updateConflict, setUpdateConflict] = useState(false);
  const [contractsList, setContractsList] = useState([]);


  // elements related to the item
  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false},
    {label: "Nazwa", accessor: "name", sortable: true, searchable: true},
    {label: "Skrócona nazwa", accessor: "shortName", sortable: true, searchable: true},
    {label: "Opis", accessor: "notes", sortable: true, searchable: true},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];

  const primaryCountry = {
    label: "Kraj",
    itemName: "billingAddressCountry",
    propertyName: "billingAddressCountryId"
  }

  const secondaryCountry = {
    label: "Kraj",
    itemName: "secondaryAddressCountry",
    propertyName: "secondaryAddressCountryId"
  }

  const primaryAddress = {
    street: "billingAddressStreet",
    streetNumber: "billingAddressStreetNumber",
    postalCode: "billingAddressPostalCode",
    city: "billingAddressCity",
  }

  const secondaryAddress = {
    street: "secondaryAddressStreet",
    streetNumber: "secondaryAddressStreetNumber",
    postalCode: "secondaryAddressPostalCode",
    city: "secondaryAddressCity",
  }

  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete,
    setRelatedItems: null
  }


  const onDelete = async () => {
    const response = await deleteItem(`${dataUrl}/${currentItem.id}`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    }
  }

  const onSaveItemClick = async (e) => {
    e.preventDefault();
    if (!currentItem.name || currentItem.legalEntity.id === 0) {
      if (!currentItem.name) {
        let nameInput = document.getElementById("name");
        nameInput.classList.add("form-input-invalid");
        nameInput.placeholder = `ten element jest wymagany`;
      }

      if (currentItem.legalEntity.id === 0) {
        let legalEntityInput = document.getElementById("legalEntity");
        legalEntityInput.classList.add("form-input-invalid");
      }
      return;
    }

    let response;
    const item = {
      id: currentItem.id,
      name: currentItem.name,
      shortName: currentItem.shortName,
      legalEntity:
        {id: currentItem.legalEntity.id},
      notes: currentItem.notes,
      nip: currentItem.nip,
      primaryPhone: currentItem.primaryPhone,
      primaryEmail: currentItem.primaryEmail,
      billingAddressStreet: currentItem.billingAddressStreet,
      billingAddressStreetNumber: currentItem.billingAddressStreetNumber,
      billingAddressPostalCode: currentItem.billingAddressPostalCode,
      billingAddressCity: currentItem.billingAddressCity,
      billingAddressCountry:
        {id: currentItem.billingAddressCountry ? currentItem.billingAddressCountry.id : 0},
      secondaryAddressStreet: currentItem.secondaryAddressStreet,
      secondaryAddressStreetNumber: currentItem.secondaryAddressStreetNumber,
      secondaryAddressPostalCode: currentItem.secondaryAddressPostalCode,
      secondaryAddressCity: currentItem.secondaryAddressCity,
      secondaryAddressCountry:
        {id: currentItem.secondaryAddressCountry ? currentItem.secondaryAddressCountry.id : 0},
      contactsIds: currentItem.contactsIds
    };

    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataUrl, item, state);
    } else {
      response = await updateItem(`${dataUrl}/${item.id}`, item, state);
    }

    if (response.status === 401 || response.status === 403) {
      navigate('/login', {state: {from: location}, replace: true});
    } else if (response.status === 409) {
      setUpdateConflict(true);
    } else {
      response && onSaveAndClose({state});
    }
  }

  const checkRelatedItems = async (id) => {
    //const data = await getRelatedChildrenByParentId(`${ relatedItemsUrl }/${ id }`, id, setEventsList);
    //data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
    setAllowDelete(true);
  }

  const onClose = () => {
    setUpdateConflict(false);
    onCloseDetails({state})
  };

  useEffect(() => {
    if (updateConflict) setUpdateConflict(false);
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
  }, [itemChanged])

  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete, currentItem.name);
    }
  }, [allowDelete])

  useEffect(() => {
    const getData = async () => {
      const [itemsData, legalEntityData, countriesData] =
        await Promise.all([
          getItems(dataUrl),
          getItems(legalEntityUrl),
          getItems(countryUrl)
        ]);

      let success = 0;
      if (itemsData.status === 200 &&
        legalEntityData.status === 200 &&
        countriesData.status === 200
      ) {
        success = 200
      } else if (itemsData.status === 401 || itemsData.status === 403
        || legalEntityData.status === 400 || legalEntityData.status === 403
        || countriesData.status === 400 || countriesData.status === 403) {
        success = 401
      }

      if (success === 200) {
        setLoading(false);
        setItems(itemsData.data);
        setLegalEntities(legalEntityData.data);
        setCountries(countriesData.data);
        //console.log(countriesData.data);
      } else if (success === 401) {
        navigate('/login', {state: {from: location}, replace: true})
      } else {
        alert("Nie udało się pobrać danych.");
      }

    };
    getData();
  }, [])


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem,
    currentFormState,
    setCurrentFormState,
    formDescription: "",
    formHeader: `Nowa ${itemName}`,
    buttonTitle: `Dodaj`
  }

  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv/>
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows={itemsList}
        columns={columns}
        state={state}
        checkRelatedItems={checkRelatedItems}
        formHeader={`Edycja ${itemName}`}
        relatedItemsUrl={relatedItemsUrl}
      />
  } else {
    dataSectionContent = <h6>NIE ZNALEZIONO ŻADNYCH DANYCH, DODAJ NOWY WPIS: {itemName.toUpperCase()}</h6>
  }

  return (
    <div id="layoutSidenav_content">
      {currentFormState.showForm ? (
        <AppComponentCardHeader
          title={`Edycja pozycji słownika ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()} -> ${currentItem.name}`}/>
      ) : (
        <AppComponentCardHeader
          title={`Pozycje słownika ${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`}
          buttonProps={addDataButtonProps}/>
      )}
      <div className="container-fluid px-4">
        {currentFormState.showForm ? (
          <div className="custom-modal shadow rounded" id="modal_wrapper">
            <div className="custom-modal-body">
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{currentFormState.formDescription}</p>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon
                    icon={faExclamationCircle}/>&nbsp;{currentFormState.formDataChangedWarning}</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={() => {
                    setUpdateConflict(false)
                    restoreFormData({state})
                  }}>
                    Wyczyść zmiany
                  </Button>
                </div>
                <div className="col-md-12 mb-md-0 mb-5">
                  <form id="add-department-form" name="add-department-form">

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="name" className="">Nazwa *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="name" required={true} state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="shortName" className="">Skrócona nazwa *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="shortName" maxlen="10" required={true} state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="nip" className="">NIP *</label>
                      </div>
                      <div className="col-md-9">
                        <TextInput propertyName="nip" maxlen="10" required={true} state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="legalEntity" className="">Forma prawna *</label>
                      </div>
                      <div className="col-md-9">
                        <Select
                          label="Forma prawna"
                          propertyName="legalEntityId"
                          required={true}
                          state={state}
                          itemsList={legalEntitiesList}
                          itemName="legalEntity"
                          displayNames={["name"]}
                        />
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="contact" className="">Kontakt</label>
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="md-form mb-0">
                              <label htmlFor="primaryPhone" className="">Główny telefon</label>
                              <TextInput propertyName="primaryPhone" state={ state }/>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="md-form mb-0">
                              <label htmlFor="primaryEmail" className="">Główny adres email</label>
                              <TextInput propertyName="primaryEmail" state={ state }/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="addresses" className="">Adresy</label>
                      </div>
                      <div className="col-md-9">
                        <div className={"row"}>
                          <div className="col-md-6">
                            <div className="md-form mb-0">
                              <div className="card">
                                <BusinessPartnerAddressCard cardHeader="Adres podatkowy" state={state}
                                                            addressProps={primaryAddress} countriesList={countriesList}
                                                            countryProps={primaryCountry}/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="md-form mb-0">
                              <div className="card">
                                <BusinessPartnerAddressCard cardHeader="Adres korespondencyjny" state={state}
                                                            addressProps={secondaryAddress} countriesList={countriesList}
                                                            countryProps={secondaryCountry}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="notes" className="">Opis</label>
                      </div>
                      <div className="col-md-9">
                        <TextArea propertyName="notes" required={false} rows="2" state={state}/>
                      </div>
                    </div>

                    <div className="row form-group mb-4">
                      <div className="col-md-3 form-label">
                        <label htmlFor="positions" className=""></label>
                      </div>

                      <div className="col-md-9">
                        <div className={"row"}>
                          <div className="col-md-6">
                            <div className="md-form mb-0">
                              <div className="card">
                                <BusinessPartnerContractsCard cardHeader="Kontrakty" state={state}
                                                            contractsList={contractsList}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>


                    <p id="conflict_notification" className={updateConflict ? "err_info" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;
                      Nie można dodać ani zmienić tego elementu, ponieważ istnieje już wpis o tej nazwie i/lub skrócie
                    </p>

                  </form>
                </div>

              </section>
            </div>
            <ModalFooter
              onDelete={onDelete}
              onCloseDetails={onClose}
              onSubmit={onSaveItemClick}
              state={state}
            />
          </div>
        ) : (
          <div
            className={itemsList.length > 0 ? "card shadow p-1 mb-5 bg-white rounded-2" : "card shadow mb-5 bg-transparent rounded-2"}>{dataSectionContent}</div>
        )}
      </div>
      <DeleteWarningModal
        state={state}
        onDelete={onDelete}
        deleteItemName={itemName}/>
    </div>
  );

}

export default BusinessPartner;

