/* eslint-disable eqeqeq */

export function isEmpty(obj = {}) {
  return Object.keys(obj).length === 0
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

export function isNumber(value) {
  return typeof value == 'number' && !isNaN(value)
}

export function isBoolean(value) {
  return value === true || value === false
}

export function isNil(value) {
  return typeof value === 'undefined' || value === null
}

export function isDateString(value) {
  if (!isString(value)) return false

  return value.match(/^\d{2}-\d{2}-\d{4}$/)
}

export function convertDateString(value) {
  return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2)
}

export function toLower(value) {
  if (isString(value)) {
    return value.toLowerCase()
  }
  return value
}

export function convertType(value) {
  if (isNumber(value)) {
    return value.toString()
  }

  if (isDateString(value)) {
    return convertDateString(value)
  }

  if (isBoolean(value)) {
    return value ? '1' : '-1'
  }

  return value
}

export function filterRows(rows, filters, columns) {
  if (isEmpty(filters)) return rows;

  return rows.filter((row) => {
    return Object.keys(filters).every((accessor) => {
      const value = row[accessor];
      const searchValue = filters[accessor].toLowerCase();
      const fieldsToInclude = columns.find(column => column.accessor === accessor)?.fieldsToInclude || [];

      if (typeof value === 'object' && fieldsToInclude.length > 0) {
        return fieldsToInclude.some(field => value && objectToString(value[field]).toLowerCase().includes(searchValue));
      }

      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchValue);
      }
      if (typeof value === 'number') {
        return value.toString().includes(searchValue);
      }
      if (typeof value === 'boolean') {
        return (searchValue === 'true' && value) || (searchValue === 'false' && !value);
      }
      return false;
    });
  });
}



export function sortRows(rows, sort, columns) {
  return rows.sort((a, b) => {
    const { order, orderBy } = sort;
    const fieldsToInclude = columns.find(column => column.accessor === orderBy)?.fieldsToInclude || [];

    const aValue = typeof a[orderBy] === 'object' ? objectToString(a[orderBy], fieldsToInclude) : a[orderBy];
    const bValue = typeof b[orderBy] === 'object' ? objectToString(b[orderBy], fieldsToInclude) : b[orderBy];

    const aLocale = convertType(aValue);
    const bLocale = convertType(bValue);

    return (order === 'asc') ? aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(bValue) }) : bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(aValue) });
  });
}


export function sortRowsOld(rows, sort) {
  return rows.sort((a, b) => {
    const { order, orderBy } = sort

    if (isNil(a[orderBy])) return 1
    if (isNil(b[orderBy])) return -1

    const aLocale = convertType(a[orderBy])
    const bLocale = convertType(b[orderBy])

    if (order === 'asc') {
      return aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(b[orderBy]) })
    } else {
      return bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(a[orderBy]) })
    }
  })
}

function objectToString(value, fieldsToInclude = []) {
  if (value === null || typeof value === 'undefined') {
    return '';
  }
  if (typeof value === 'object') {
    if (fieldsToInclude.length > 0) {
      return fieldsToInclude.map(field => objectToString(value[field])).join(' ');
    }
    return Object.values(value).map(subValue => objectToString(subValue)).join(' ');
  }
  return value.toString();
}


export function paginateRows(sortedRows, activePage, rowsPerPage) {
  return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
}


export function getValueByPath(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}