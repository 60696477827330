import React from 'react';
import AppAddDataButton from "../common/AppAddDataButton";

const AppComponentCardHeader = ({ title, buttonProps }) => {
  return (
    <div className="app-component-card-header shadow">
      {title}
      {/*{buttonProps && <AppAddDataButton props = {buttonProps} />}*/}
      {buttonProps && <AppAddDataButton { ...buttonProps } />}
    </div>
  );
};

export default AppComponentCardHeader;