export let contractDefault = {
  id: "",
  docType: "",
  identifier: "",
  dateFrom: "",
  dateTo: "",
  contractType: {id: 0},
  businessPartner: {id: 0},
  employee: {id: 0},
  notes: "",
  legalPerson: {id: 0},
  status: "",
  number: 0,
  year: 0,
  month: 0,
  symbol: ""

}


export let hiringStageDefault = {
  id: "",
  name: "",
  shortName: "",
  description: "",
};


export let documentNumberingPatternDefault = {
  id: "",
  year: 0,
  symbol: "",
  yearFormat: "rrrr",
  monthFormat: "mm",
  pattern: "",
  contractType: {id: 0},
  locked: false
};


export let employeeCompactedDefault = {
  bankAccountNumber: "",
  dateOfBirth: "",
  evaluation: "",
  fathersName: "",
  firstName: "",
  ICEContact: "",
  id: "",
  lastName: "",
  login: "",
  maidenName: "",
  mothersName: "",
  nip: "",
  password: "",
  pesel: "",
  placeOfBirth: "",
  primaryEmail: "",
  primaryPhone: "",
  secondName: "",
  secondaryEmail: "",
  secondaryPhone: "",
  type: "",
  userRoles: [],
  photos: ""
}

export let employeeDefault = {
  bankAccountNumber: "",
  dateOfBirth: "",
  evaluation: "",
  fathersName: "",
  firstName: "",
  ICEContact: "",
  id: "",
  lastName: "",
  login: "",
  maidenName: "",
  mothersName: "",
  nip: "",
  password: "",
  pesel: "",
  placeOfBirth: "",
  primaryEmail: "",
  primaryPhone: "",
  secondName: "",
  secondaryEmail: "",
  secondaryPhone: "",
  type: "",
  userRoles: [],
  photos: ""
}

export let commendationDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  weight: "",
  description: "",
  companySectionTypeList: []
}

export let infractionDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  weight: "",
  description: "",
  companySectionTypeList: []
};

export let workingTimeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  workingHours: "",
  description: ""
};

export let nonWorkingDaysDefault = {
  selected: false,
  id: "",
  name: "",
  date: "",
  shortName: "",
  description: ""
};


export let businessPartnerDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  legalEntity:
    {id: 0},
  notes: "",
  nip: "",
  primaryPhone: "",
  primaryEmail: "",
  billingAddressStreet: "",
  billingAddressStreetNumber: "",
  billingAddressPostalCode: "",
  billingAddressCity: "",
  billingAddressCountry:
    {id: 0},
  secondaryAddressStreet: "",
  secondaryAddressStreetNumber: "",
  secondaryAddressPostalCode: "",
  secondaryAddressCity: "",
  secondaryAddressCountry:
    {id: 0},
  contactsIds : []

};

export let countryDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: ""
};


export let voivodeshipDefault = {
  selected: false,
  id: "",
  name: ""
};



export let nfzBranchDefault = {
  selected: false,
  id: "",
  name: "",
  identifier: "",
  description: ""
};

export let legalEntityTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let contractTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: "",
  color: "",
  legalPersonJuridical: false,
  legalPersonNatural: false,
};


export let departmentDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: "",
  manager: {id: 0},
  deputyManager: {id: 0},
  positions: [],
  email: "",
  phoneNumber: ""
};


export let medicalExaminationTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let genderDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let trainingTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};


export let documentTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let identityCardTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let certificateTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
};

export let positionDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  basicWage: 0.0,
  description: "",
  department: {id: 0}
};

export const equipmentOwnershipTypeDefault = {
  id: "",
  name: "",
  description: ""
}

export const equipmentStatusDefault = {
  id: "",
  name: ""
}

export const absenceTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
}

export const businessTripTypeDefault = {
  selected: false,
  id: "",
  name: "",
  shortName: "",
  description: ""
}

export const equipmentBookingStatusDefault = {
  id: "",
  name: "",
  description: "",
  color: ""
}

export const equipmentCategoryDefault = {
  id: "",
  name: "",
  description: ""
}

export let taxOfficeDefault = {
  selected: false,
  id: "",
  name: "",
  address: "",
  postalCode: "",
  city: "",
  code: "",
  voivodeship: {
    id: 0
  }
};

export const equipmentDefault = {
  id: 0,
  sortingId: 0,
  name: "",
  notes: "",
  width: 0,
  length: 0,
  height: 0,
  weight: 0,
  powerRequired: 0,
  staffNeeded: 0,
  minimumAge: 0,
  maxParticipants: 0,
  equipmentCategory: {
    id: 0
  },
  inUse: false,
  photos:[],
  equipmentStatus: {
    id: 0
  },
  bookingStatus: {
    id: 0
  },
  equipmentOwnership: {
    id: 0
  }
}

export const userCompactedDefault = {
  id: 0,
  login: "",
  password: "",
  firstName: "",
  lastName: "",
  userRoles: [{}]
}

export const userDefault = {
  id: 0,
  login: "",
  password: "",
  firstName: "",
  lastName: "",
  userRoles: [{}]
}

export const userRoleDefault = {
  id: "",
  name: "",
  description: ""
}


export const settingsDefault = {
  id: "",
  infractionWeightMinimumValue: 0,
  infractionWeightMaximumValue: 5,
  commendationWeightMinimumValue: 0,
  commendationWeightMaximumValue: 5,
  sameManagerInManyDepartments: false
}

export const companyDefault = {
  id: 0,
  fullName: "",
  shortName: "",
  regon: "",
  nip: "",
  krs: "",
  insuranceNumber: "",
  legalEntity: {id: 0},
  taxOffice: {id: 0},
  notes: "",
  representative: {id: 0},
  primaryStreet: "",
  primaryPostalCode: "",
  primaryCity: "",
  primaryCountry: {id: 0},
  secondaryStreet: "",
  secondaryPostalCode: "",
  secondaryCity: "",
  secondaryCountry: {id: 0},
  email: "",
  website: "",
  bankAccount: ""
}