import React, { useState, useEffect, useRef } from 'react';
import { resetInvalidInputField } from "../../js/CommonHelper";
import { getItemById } from "../../helpers/ComponentHelper";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Select = ({ label, propertyName, required, disabled, state, itemsList, itemName, displayNames }) => {
  const currentItem = state.currentItem;
  const setCurrentItem = state.setCurrentItem;
  const currentFormState = state.currentFormState;
  const setCurrentFormState = state.setCurrentFormState;
  const itemChanged = state.itemChanged;
  const setItemChanged = state.setItemChanged;
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleReset = () => {
    const updatedItem = { ...currentItem, [itemName]: undefined };
    setCurrentItem(updatedItem);
    setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
    setItemChanged(!itemChanged);
  };

  const handleSelectChange = (item) => {
    const relatedObject = getItemById(itemsList, item.id);
    setCurrentItem({ ...currentItem, [itemName]: relatedObject });
    setCurrentFormState({ ...currentFormState, formSaveButtonDisabled: false });
    setItemChanged(!itemChanged);
    setIsOpen(false);
    setSearchTerm("");
    resetInvalidInputField(itemName);
  };

  const filteredItems = itemsList.filter(item =>
    displayNames.some(displayName =>
      item[displayName].toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }} ref={selectRef}>
      <div style={{ flex: 1 }}>
        <div className="form-select"
             onClick={() => setIsOpen(!isOpen)}
             style={{ cursor: 'pointer' }}
             id={itemName}
        >
          {currentItem[itemName] && currentItem[itemName].id !== 0
            ? displayNames.map(displayName => currentItem[itemName][displayName]).join(' ')
            : '-- Wybierz --'}
        </div>
        {/*<div className="form-select" onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>*/}
        {/*  {currentItem[itemName] ? displayNames.map(displayName => currentItem[itemName][displayName]).join(' ') : '-- Wybierz --'}*/}
        {/*</div>*/}
        {isOpen && (
          <div style={{ position: 'absolute', zIndex: 1, background: 'white', border: '1px solid #ccc', width: '100%' }}>
            <input
              type="text"
              className="form-control"
              placeholder="Wyszukaj..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoFocus
              disabled={disabled}
            />
            <div className="dropdown-menu show">
              {filteredItems.map((item) => (
                <button
                     key={item.id}
                  className="dropdown-item"
                  onClick={() => handleSelectChange(item)}
                  disabled={disabled}
                >
                  {displayNames.map(displayName => item[displayName]).join(' ')}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <button onClick={handleReset} disabled={!(currentItem[itemName] && currentItem[itemName].id > 0)} className='btn btn-outline-danger'>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    </div>
  );
};

export default Select;







// import React from 'react';
// import {resetInvalidInputField} from "../../js/CommonHelper";
// import {getItemById} from "../../helpers/ComponentHelper";
// import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//
// const Select = ({label, propertyName, required, disabled, state, itemsList, itemName, displayNames}) => {
//   // displayNames is a list of combined item properties to be shown in Select list
//
//   const currentItem = state.currentItem;
//   const setCurrentItem = state.setCurrentItem;
//   const currentFormState = state.currentFormState;
//   const setCurrentFormState = state.setCurrentFormState;
//   const itemChanged = state.itemChanged;
//   const setItemChanged = state.setItemChanged;
//
//
//   const handleReset = () => {
//     const updatedItem = { ...currentItem, [itemName]: undefined };
//     setCurrentItem(updatedItem);
//     setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
//     setItemChanged(!itemChanged);
//   };
//
//
//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <select className="form-select"
//               aria-label={label}
//               id={itemName}
//               name={propertyName}
//               required={required}
//               disabled={disabled}
//               value={currentItem[itemName]?.id || ""}
//               onChange={(e) => {
//                 const relatedObject = getItemById(itemsList, e.target.value);
//                 setCurrentItem({...currentItem, [itemName]: relatedObject});
//                 setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
//                 setItemChanged(!itemChanged);
//               }}
//               onClick={() => {
//                 resetInvalidInputField(propertyName);
//               }}
//       >
//         <option disabled value="">-- Wybierz --</option>
//         {itemsList.map((item) => (
//           <option key={item.id} value={item.id}>
//             {displayNames.map((displayName) => item[displayName]).join(' ')}
//           </option>
//         ))}
//       </select>
//       <button onClick={handleReset} disabled={!(currentItem[itemName] && currentItem[itemName].id > 0)} className='btn btn-outline-danger'>
//         <FontAwesomeIcon icon={faTrashAlt}/>
//       </button>
//     </div>
//   );
//
// };
//
// export default Select;
