import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuLink = ({ path, label }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <Link
      className={`nav-link ${isActive ? 'active' : ''}`}
      to={path}
    >
      <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
      {label}
    </Link>
  );
};

export default MenuLink;
