import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Tab, Container } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faInfoCircle, faEye, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ModalFooter from "../../common/ModalFooter";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultFormState } from "../../../../defaults/Forms";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { employeeCompactedDefault, employeeDefault } from "../../../../defaults/Items";
import {
  onSaveAndClose,
  compareData,
  restoreFormData,
  onItemsListDeleteButtonClick,
  onCloseDetails
} from "../../../../helpers/ComponentHelper";

import AppComponentCardHeader from "../../common/AppComponentCardHeader";
import LoadingDataDiv from "../../common/LoadingDataDiv";
import AppAddDataButton from "../../common/AppAddDataButton";
import DeleteWarningModal from "../../common/DeleteWarningModal";
import ItemDetailsModalHeader from "../../common/ItemDetailsModalHeader";
import TextInput from "../../../elements/TextInput";
import TextArea from "../../../elements/TextArea";
import RelatedItemsList from "../../common/RelatedItemsList";
import useCrud from "../../../../hooks/useCrud";
import { Table } from "../../../table/Table";
import UserRolesCard from "../users/UserRolesCard";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAxiosPrivateFileUpload from "../../../../hooks/useAxiosPrivateFileUpload";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/; // requires 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special char and has to be 8-24 chars long

const Employee = () => {

  const dataUrl = "/employee";
  const dataFullUrl = "/employee";
  const appRolesUrl = "/admin/role";
  const imagesFolder = "../images/employees_photos/";
  const employeeImagePlaceholder = "../images/employees_photos/placeholder.jpg";
  const defaultItem = employeeCompactedDefault;
  const defaultFullItem = employeeDefault;
  const itemName = "pracownik";
  const itemNames = "pracownicy";
  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateFileUpload = useAxiosPrivateFileUpload();

  const navigate = useNavigate();
  const location = useLocation();
  const { createItem, updateItem, deleteItem, getItems, getRelatedChildrenByParentId } = useCrud(dataUrl);

  const [loading, setLoading] = useState(true);
  const [allowDelete, setAllowDelete] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(defaultFormState);
  const [itemsList, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(defaultFullItem);
  const [backupItem, setBackupItem] = useState(defaultItem);
  const [itemChanged, setItemChanged] = useState(false);
  const [appRoles, setAppRoles] = useState([]);
  const [validPassword, setValidPassword] = useState(true);
  const [pwd, setPwd] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");
  // elements related to the item

  const fileInput = useRef(null);
  const hiddenFileInput = useRef(null);

  const columns = [
    {label: "Id", accessor: "id", sortable: true, searchable: false, visible: false},
    {label: "Login", accessor: "login", sortable: true, searchable: true},
    {label: "Imię", accessor: "firstName", sortable: true, searchable: true},
    {label: "Nazwisko", accessor: "lastName", sortable: true, searchable: true},
    {label: "Edycja", accessor: "editBtn", sortable: false, searchable: false},
    {label: "Usuń", accessor: "deleteBtn", sortable: false, searchable: false},
  ];
  const passwordRef = useRef();

  const onDelete = async () => {
    const response = await deleteItem(`${ dataFullUrl }/${ currentItem.id }`, currentItem.id, state);
    if (response === 401 || response === 403) {
      navigate('/login', { state: { from: location }, replace: true });
    }
  }

  const validatePassword = (newPassword) => {

    if (newPassword === "") {
      setValidPassword(false);
      console.log("Hasło nie może być puste")
      return;
    }

    if ((newPassword && !PWD_REGEX.test(newPassword)) && newPassword !== "123") {
      setValidPassword(false);
      console.log("Hasło musi się składać z " +
        " 8 do 24 znaków. " +
        " Musi zawierać przynajmniej jedną wielką i jedną małą literę, cyfrę i znak specjalny.)");
      return false;
    }
    return true;
  }

  const deleteImageLink = () => {
    setCurrentItem({...currentItem, photo: ""})
  }

  const onSaveItemClick = async (e) => {
    e.preventDefault();
    if(!currentItem.login || currentItem.login.length < 4) {
      let nameInput = document.getElementById("login");
      nameInput.classList.add("form-input-invalid");
      nameInput.placeholder = `ten element jest wymagany`;
      return;
    }

    if(currentItem.userRoles.length === 0) {
      let userRolesDiv = document.getElementById("userRoles");
      userRolesDiv.classList.add("form-input-invalid");
      return;
    }

    const newPassword = document.getElementById("password");
    if (!validatePassword(newPassword.value)) {
      newPassword.classList.add("form-input-invalid");
      return
    }

    let response;
    let password;
    if (!currentFormState.formAddingDataMode) {
      password = newPassword.value ? newPassword.value : currentItem.password;
    } else {
      password = currentItem.password;
    }

    const item = {
      id: currentItem.id,
      login: currentItem.login,
      password: password,
      firstName: currentItem.firstName,
      lastName: currentItem.lastName,
      userRoles: currentItem.userRoles,
      photos: currentItem.photos
    }

    const itemToSave = {
      login: currentItem.login,
      password: password,
      firstName: currentItem.firstName,
      lastName: currentItem.lastName,
      userRoles: currentItem.userRoles,
      photos: currentItem.photos
    }
    if (imageFile) uploadImage(imageFile);

    if (currentFormState.formAddingDataMode) {
      response = await createItem(dataFullUrl, itemToSave, state);
    } else {
      response = await updateItem(`${ dataFullUrl }/${ item.id }`, item, state);
    }
    if (response === 401 || response === 403) navigate('/login', { state: { from: location }, replace: true });

    if (imageFile) {
    }

    response && onSaveAndClose({state});
  }

  // const checkRelatedItems = async (id) => {
  //   const data = await getRelatedChildrenByParentId(`${ userClientsUrl }/${ id }`, id, setUserClientsList);
  //   data.length === 0 ? setAllowDelete(true) : setAllowDelete(false);
  // }

  const onClose = () => {
    setPwd('');
    setImageFile(null);
    onCloseDetails({ state })
  };

  useEffect(() => {
    compareData(currentFormState, setCurrentFormState, currentItem, backupItem);
    //console.log("CURENT ITEM:" + currentItem.firstName + "/" + currentItem.photos);
  }, [itemChanged])

  useEffect(() => {
    if (allowDelete !== null) {
      onItemsListDeleteButtonClick(currentFormState, setCurrentFormState, itemName, allowDelete);
    }
  }, [allowDelete])


  const passwordToggler = () => {
    const password = document.getElementById("password");
    const toggler = document.getElementById("password_toggler");
    if (password.type === "password") {
      password.type = "text";
      toggler.classList.add("pwd_visible");
    } else {
      password.type = "password";
      toggler.classList.remove("pwd_visible")
    }
  }


  useEffect(() => {
    if (currentItem === backupItem) {
      setPwd('');
    }
  }, [currentItem])


  useEffect(() => {
    const getData = async () => {
      const [itemsData, appRoles] =
        await Promise.all([
          getItems(dataUrl),
          getItems(appRolesUrl),
        ]);

      const success =
        itemsData.status === 200 &&
        appRoles.status === 200;

      if (success) {
        setLoading(false);
        setItems(itemsData.data);
        setAppRoles(appRoles.data);
      } else {
        navigate('/login', { state: { from: location }, replace: true})
      }
    };

    getData();
  }, [])


  useEffect(() => {
    if (pwd !== '') {
      setValidPassword(PWD_REGEX.test(pwd))
    } else {
      setValidPassword(true);
    }
  }, [pwd])


  const getCompleteItem = async (id) => {
    const response = await getItems(`${dataFullUrl}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 || response.status === 403) {
      navigate('/login', { state: { from: location }, replace: true})
    } else {
      alert("Nie udało się pobrać danych.");
    }
  }


  const addDataButtonProps = {
    setCurrentItem,
    setBackupItem,
    defaultItem: defaultFullItem,
    currentFormState,
    setCurrentFormState,
    formDescription: ``,
    formHeader: `Nowy ${ itemName }`,
    buttonTitle: `Dodaj`
  }

  const state = {
    itemsList, setItems,
    currentItem, setCurrentItem,
    currentFormState, setCurrentFormState,
    defaultItem, backupItem, setBackupItem, itemChanged, setItemChanged,
    setAllowDelete
  }

  let dataSectionContent;
  if (loading) {
    dataSectionContent = <LoadingDataDiv />
  } else if (itemsList.length > 0) {
    dataSectionContent =
      <Table
        rows = { itemsList }
        columns = { columns }
        state = { state }
        // checkRelatedItems = { checkRelatedItems }
        formHeader = {`Edycja pracownika`}
        // relatedItemsUrl = { userClientsUrl }
        getCompleteItem = { getCompleteItem }
      />
    // console.log(itemsList);
  } else {
    dataSectionContent = <h6>NO DATA FOUND, PLEASE ADD A NEW `${itemName.toUpperCase()}`</h6>
  }

  const onPasswordChange = (e) => {
    setItemChanged(!itemChanged);
    setCurrentItem(currentItem => ({...currentItem,
      password: e.target.value === "" ? backupItem.password : e.target.value}));
    setCurrentFormState({...currentFormState, formSaveButtonDisabled: false});
    setPwd(e.target.value);
  }

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("imageFile", file);
    axiosPrivateFileUpload.post('http://localhost:8080/employee/upload-image', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => console.log("sent"))
      .catch(error => console.log("ERROR!:" + error))
  }


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imgName = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    //console.log("FILE");
    //console.log(file);

    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgName, {
              type: "image/png",
              lastModified: Date.now(),
            });
            setImageFile(file);
            setItemChanged(!itemChanged);
          },
          "image/jpeg",
          1
        );

        setCurrentItem({...currentItem, photos: event.target.files[0].name});
      };
    };
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };


  return (

    <div id="layoutSidenav_content">
      <div className="container-fluid px-4">
        <div className="card my-3 shadow mb-5 bg-white rounded">
          <AppComponentCardHeader title={`${itemNames.charAt(0).toUpperCase()}${itemNames.slice(1).toLowerCase()}`} buttonProps={addDataButtonProps}/>
          { dataSectionContent }
        </div>
      </div>

      <DeleteWarningModal
        state = { state }
        onDelete = { onDelete }
        deleteItemName ={ itemName } />

      <Modal show={ currentFormState.showForm }
             size="xl"
             backdrop="static"
             keyboard={ false }
             onHide={ onClose }>
        <ItemDetailsModalHeader title ={ currentFormState.formHeader } />
        <Modal.Body style = {{backgroundColor: "rgb(245,245,245)"}}>

          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Dane osobowe">
              Dane osobowe
              <section className="mb-4">
                <p className="text-center w-responsive mx-auto mb-1 form_test">{ currentFormState.formDescription }</p>
                <span onClick={() => console.log(currentItem)}><FontAwesomeIcon icon={ faQuestionCircle }/>dddd</span>
                <div>
                  <p className="text-center w-responsive mx-auto mb-1 data_changed" id="data-changed"><FontAwesomeIcon icon={ faExclamationCircle }/>&nbsp;{ currentFormState.formDataChangedWarning }</p>
                  <Button variant="secondary" id="btn-restore" className="btn-restore" onClick={ () => {
                    restoreFormData({ state })}}>
                    Cancel all changes
                  </Button>
                </div>

                <div className="row">
                  <div className="col-md-12 mb-md-0 mb-5">
                    <form id="add-item-form" name="add-item-form">
                      <div className="col">
                        <div className="col-md-4">
                          <div className="md-form mb-0">
                            <label htmlFor="login" className="">Login</label>
                            <TextInput propertyName="login" required={true} state={ state }/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="md-form mb-0">
                            <label htmlFor="firstName" className="">Imię</label>
                            <TextInput propertyName="firstName" required={true} state={ state }/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="md-form mb-0">
                            <label htmlFor="lastName" className="">Nazwisko</label>
                            <TextInput propertyName="lastName" required={true} state={ state }/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8 mt-3">
                          <div className="md-form mb-0">
                            Wpisz hasło tylko wtedy, kiedy chcesz je zmienić
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="md-form mb-0">
                            <label  htmlFor="password" className="">Nowe hasło</label>
                            <div style={{position: "relative"}}>
                              <input
                                className="form-control"
                                type="password"
                                id="password"
                                onChange={(e) => onPasswordChange(e)}
                                defaultValue={pwd}
                                required
                                ref={ passwordRef }
                              />
                              <span id="password_toggler" className="password_toggler" onClick={()=>passwordToggler()}><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></span>
                            </div>
                            <p id="pwdnote" className={!validPassword ? "err_info" : "offscreen"}>
                              <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                              8 do 24 znaków. Musi zawierać przynajmniej jedną wielką i jedną małą literę, cyfrę i znak specjalny.
                              Dozwolone znaki specjalne: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row margin-top">
                        {/*<div className="col-md-6">*/}
                        {/*  <UserContactCard state = { state }/>*/}
                        {/*</div>*/}
                        <div className="col-md-6">
                          <UserRolesCard state = { state } appRoles = { appRoles }/>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </section>
            </Tab>

            <Tab eventKey="profile" title="Dane firmowe">
              <span style={{fontWeight: 600, fontFamily: "Arial", fontSize: "1.5rem"}}>{currentItem.firstName} {currentItem.lastName}</span>
              <Container style = {{padding: "4px"}}>

                  <div className="col-md-12 mb-md-0 mb-5">
                    <form id="add-item-form" name="add-item-form">

                      <div className="row mb-2 form-component-group">

                        <div className="col-md-3">
                            <label htmlFor="login" className="label-padding-2">Login</label>
                            <TextInput propertyName="login" required={true} state={ state }/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="password" className="label-padding-2">Hasło</label>
                            <TextInput propertyName="firstName" required={true} state={ state }/>
                        </div>

                      </div>

                      <div className="row mb-2 form-component-group">

                        <div className="col-md-3">
                          <div className="col-md-12">
                            <label htmlFor="firstName" className="label-padding-2">Imię</label>
                            <TextInput propertyName="firstName" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="secondName" className="label-padding-2">Drugie imię</label>
                            <TextInput propertyName="secondName" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="lastName" className="label-padding-2">Nazwisko</label>
                            <TextInput propertyName="lastName" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="maidenName" className="label-padding-2">Nazwisko panieńskie</label>
                            <TextInput propertyName="maidenName" required={true} state={ state }/>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="col-md-12">
                            <label htmlFor="dateOfBirth" className="label-padding-2">Data urodzenia</label>
                            <TextInput propertyName="dateOfBirth" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="placeOfBirth" className="label-padding-2">Miejsce urodzenia</label>
                            <TextInput propertyName="placeOfBirth" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="fathersName" className="label-padding-2">Imię ojca</label>
                            <TextInput propertyName="fathersName" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="mothersName" className="label-padding-2">Imię matki</label>
                            <TextInput propertyName="mothersName" required={true} state={ state }/>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="col-md-12">
                            <label htmlFor="primaryPhone" className="label-padding-2">Telefon 1</label>
                            <TextInput propertyName="primaryPhone" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="secondaryPhone" className="label-padding-2">Telefon 2</label>
                            <TextInput propertyName="secondaryPhone" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="primaryEmail" className="label-padding-2">Email 1</label>
                            <TextInput propertyName="primaryEmail" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="secondaryEmail" className="label-padding-2">Email 2</label>
                            <TextInput propertyName="secondaryEmail" required={true} state={ state }/>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="ICEContact" className="label-padding-2">Kontakt w razie nagłego wypadku</label>
                            <TextInput propertyName="ICEContact" required={true} state={ state }/>
                          </div>
                        </div>

                        <div className="col-md-3" style = {{border: "1px solid red"}}>
                          <div className="image-upload-container">
                            <div className="box-decoration">
                              <label htmlFor="image-upload-input" className="image-upload-label">
                                {currentItem.photos ? "Zdjęcie" : "Wybierz zdjęcie"}
                              </label>
                              <div onClick={handleClick} style={{ cursor: "pointer" }}>
                                {(imageFile ? (
                                  <img src={URL.createObjectURL(imageFile)} alt="upload image" className="img-display-after" />
                                ) : (currentItem.photos ? (
                                  <img src={ imagesFolder + currentItem.photos } alt="Brak zdjęcia" className="img-display-after" />
                                ) : <img src={ employeeImagePlaceholder } alt="Brak zdjęcia" className="img-display-before" />))}

                                <input
                                  id="image-upload-input"
                                  type="file"
                                  onChange={ handleImageChange }
                                  ref={ hiddenFileInput }
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                       </div>

                      <div className="row">
                        <div className="col-md-8 mt-3">
                          <div className="md-form mb-0">
                            Wpisz hasło tylko wtedy, kiedy chcesz je zmienić
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="md-form mb-0">
                            <label  htmlFor="password" className="">Nowe hasło</label>
                            <div style={{position: "relative"}}>
                              <input
                                className="form-control"
                                type="password"
                                id="password"
                                onChange={(e) => onPasswordChange(e)}
                                defaultValue={pwd}
                                required
                                ref={passwordRef}
                              />
                              <span id="password_toggler" className="password_toggler" onClick={()=>passwordToggler()}><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></span>
                            </div>
                            <p id="pwdnote" className={!validPassword ? "err_info" : "offscreen"}>
                              <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                              8 do 24 znaków. Musi zawierać przynajmniej jedną wielką i jedną małą literę, cyfrę i znak specjalny.
                              Dozwolone znaki specjalne: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row margin-top">
                        <div className="col-md-6">
                          <UserRolesCard state = { state } appRoles = { appRoles }/>
                        </div>
                      </div>

                    </form>
                  </div>
              </Container>
            </Tab>


            <Tab eventKey="contact" title="Contact" disabled>
              Tab content for Contact
            </Tab>
          </Tabs>


        </Modal.Body>

        <ModalFooter
          onDelete = { onDelete }
          onCloseDetails = { onClose }
          onSubmit = { onSaveItemClick }
          state = { state }
        />

      </Modal>
    </div>
  )

}

export default Employee;