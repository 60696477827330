import React from 'react';
import Button from "react-bootstrap/Button";
import {clearCurrentItem, onAddDataClick} from "../../../helpers/ComponentHelper";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const AppAddDataButton = ({ setCurrentItem, setBackupItem, defaultItem, currentFormState, setCurrentFormState, formDescription, formHeader, buttonTitle } ) => {
  return (
      <Button className="RAM_button" id="addData"
              onClick={()=>{
                clearCurrentItem(setCurrentItem, setBackupItem, defaultItem);
                onAddDataClick(currentFormState, setCurrentFormState, formDescription, formHeader);
              }}>
        <FontAwesomeIcon
          icon={ faCirclePlus }/> { buttonTitle } </Button>
  );
};

// const AppAddDataButton = ( buttonProps ) => {
//   return (
//       <Button className="RAM_button" id="addData"
//               onClick={()=>{
//                 clearCurrentItem(buttonProps.props.setCurrentItem, buttonProps.props.setBackupItem, buttonProps.props.defaultItem);
//                 onAddDataClick(buttonProps.props.currentFormState, buttonProps.props.setCurrentFormState, buttonProps.props.formDescription, buttonProps.props.formHeader);
//               }}>
//         <FontAwesomeIcon
//           icon={ faCirclePlus }/> { buttonProps.props.buttonTitle } </Button>
//   );
// };
//
export default AppAddDataButton;
