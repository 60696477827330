import React from 'react';

const RelatedItemsList = ({ label, propertyName, state, itemsList, itemName, displayNames }) => {
  const { currentItem, setCurrentItem, currentFormState, setCurrentFormState, itemChanged, setItemChanged } = state;

  const itemList = currentItem[itemName] || [];

  return (
    <div>
      <div className={"options-list-label"}>
        <label>{label}</label>
      </div>
      <div className={"options-list-container"}>
        {itemsList.map(item => (
          <div key={item.id} className="checkbox-container">
            <input
              type="checkbox"
              id={`checkbox-${item.id}`}
              checked={itemList.some(ci => ci.id === item.id)}
            />
            {displayNames.map((displayName) => item[displayName]).join(' ')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedItemsList;
